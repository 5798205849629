@import '../../../common/styles/colors.module.scss';
@import '../../../common/styles/breakpoints.module.scss';

.cardWrapper {
    background-color: #FFF;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
    width: 100%;
    overflow: auto;

    @include breakpoint-max(md) {
        padding: 1em 2em;
        border-radius: 5px;
    }

    .imageWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 300px;
        height: 120px;
        margin: 0 0 1em;

        img {
            width: 100%;
            max-height: 100px;
        }
    }

    .schemaOverview {
        width: 100%;
        padding: 1em 0 0;
        background-color: rgba(196, 196, 196, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 1em;
        border-bottom: 1px solid $black-font;

        h2 {
            font-size: 1.2em;
        }

        .tipsWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 0.5em;
        }
    }

    .buttonsWrapper {
        width: 100%;
        padding: 1em 0;
        display: flex;
        justify-content: flex-end;
        gap: 1vw;

        @include breakpoint-max(md) {
            justify-content: space-between;
        }
    }
}