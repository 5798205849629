.footerWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    padding: 2em 0;

    img {
        width: 150px;
    }

    a {
        border: none;
        outline: none;
        background-color: transparent;

        &:hover {
            cursor: pointer;
        }
    }
}