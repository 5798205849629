@import '../../common/styles/colors.module.scss';

.button {
  padding: 0.7em 1em;
  border-radius: 0.2em;
  outline: none;
  border: none;
  background-color: transparent;

  &.hollow {
    border: 1px solid $green-main;
    &:hover {
        background-color: $green-main;
    }
  }

  &.filled {
    background-color: $green-main;
    &:hover {
        opacity: 0.8;
    }
  }
}
