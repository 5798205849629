@import '../../common/styles/colors.module.scss';

@mixin active {
    background-color: $green-main;
    font-weight: 700;
}

.link {
    color: $black-font;
    text-decoration: none !important;
    border: none !important;
    outline: none !important;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;

    &:hover {
        @include active;
    }
}

.active {
    @include active;
}