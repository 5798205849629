@import '../../common/styles/colors.module.scss';
@import '../../common/styles/breakpoints.module.scss';

.content {
display: flex;
flex-direction: column;
justify-content: center;
gap: 2vh;
padding: 1vh 3.5vw;

    @include breakpoint-max(md) {
        padding: 1vh 6vw;
    }

    .title__wrapper {
        .title {
            text-align: left;
            font-size: 2.7em;
            line-height: 1.2em;
            font-weight: 700;
            color: $black-font;

            @include breakpoint-max(md) {
                font-size: 2em;
            }
        }

        p {
            font-size: 1.2em;
            line-height: 1.4em;
            margin-top: 1em;

            @include breakpoint-max(md) {
                font-size: 1.2em;
                line-height: 1.4em;
            }
        }
    }

    .content__wrapper {
        display: flex;
        width: 100%;
        justify-content: stretch;
        border-radius: 10px;
        padding: 1vw 0;

        @include breakpoint-max(md) {
            flex-direction: column;
        }

        .all-programs__wrapper {
            width: 35%;
            height: 80vh;
            overflow-y: auto;
            margin: 0.5em 0;
            position: relative;
            border-right: 1px solid $green-main-dark;
            display: flex;
            justify-content: stretch;

            @include breakpoint-max(md) {
                width: 100%;
                border-right: none;
                display: block;
            }

            .thumbnails__wrapper {
                .thumbnail__sticky {
                    position: sticky;
                    top: 0;
                    z-index: 1000;
                }
            }
        }
    }
}

.loadingWrapper {
    position: absolute;
    left: 50%;
    top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}